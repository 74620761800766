<template>
  <v-dialog v-model="deleteDialogValue" max-width="500px" persistent>
    <v-card>
      <v-card-title> Verwijderen </v-card-title>
      <v-card-subtitle>
        Bent u zeker dat u het volgende wilt verwijderen?
      </v-card-subtitle>
      <v-card-text>
        <v-row class="pt-1">
          <v-col cols="1">
            <v-icon :color="toDeleteTask.color"> mdi-circle </v-icon>
          </v-col>
          <v-col cols="2">
            <span>{{ toDeleteTask.tactTime }} min </span>
          </v-col>
          <v-col cols="9" sm="5">
            <strong>{{ toDeleteTask.operationName }}</strong>
            <br />
            ({{ toDeleteTask.cellName }})
          </v-col>
          <v-col cols="12" sm="4">
            <span>{{ toDeleteTask.description }}</span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="deleteDialogValue = false">
          Nee
        </v-btn>
        <v-btn color="primary" text @click="deleteTask()"> Ja </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    deleteDialog: {
      type: Boolean,
      required: true,
    },
    toDeleteTask: {
      type: Object,
      required: true,
    },
  },
  computed: {
    deleteDialogValue: {
      get: function () {
        return this.deleteDialog;
      },
      set: function (newValue) {
        this.$emit("update:deleteDialog", newValue);
      },
    },
  },
  methods: {
    deleteTask() {
      this.$emit("deleteTask", this.toDeleteTask);
      this.deleteDialogValue = false;
    },
  },
};
</script>
