<template>
  <v-row>
    <!-- routingSteps Column -->
    <v-col cols="12" sm="4">
      <v-card class="grey lighten-5">
        <v-card-title class="grey lighten-2">
          {{ board.columns[0].name }}
        </v-card-title>
        <v-card-text class="colHeight">
          <Container
            behaviour="copy"
            drag-class="card-ghost"
            drop-class="card-ghost-drop"
            group-name="1"
            :get-child-payload="getChildPayload1"
          >
            <Draggable
              v-for="task in board.columns[0].tasks"
              :key="task.idTask"
              class="draggable-item"
            >
              <v-row class="mt-1">
                <v-col>
                  <v-card
                    outlined
                    :style="{
                      border: '10px solid',
                      borderRadius: '20px',
                      borderColor: task.color,
                    }"
                  >
                    <v-card-text v-if="task">
                      {{ task.processName }}
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- </div> -->
            </Draggable>
          </Container>
        </v-card-text>
      </v-card>
    </v-col>
    <!-- Timeline Column -->
    <v-col cols="12" sm="8" style="flex: 1">
      <v-card class="grey lighten-5">
        <v-card-title class="grey lighten-2">
          {{ board.columns[1].name }}
          <v-spacer />
          <v-btn icon @click="editName(board.columns[1])">
            <v-icon dark> mdi-pencil </v-icon>
          </v-btn>
          <v-btn icon @click="saveBoard(board.columns[1])" :loading="savingJob">
            <v-icon dark> mdi-content-save </v-icon>
          </v-btn>
          <br />
          <v-switch
            v-if="showForecastSwitch"
            v-model="board.columns[1].forecast"
            label="forecast"
            class="ma-0 pl-1"
            hide-details
          >
          </v-switch>
          <v-btn
            v-if="showJsonButton"
            icon
            @click="getJson(board.columns[1])"
            :loading="gettingJson"
          >
            <v-icon dark> mdi-code-json </v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <slot />
          <v-timeline align-top dense>
            <Container
              group-name="1"
              :get-child-payload="getChildPayload2"
              @drop="onDrop($event)"
              drag-class="card-ghost"
              drop-class="card-ghost-drop"
              :drop-placeholder="dropPlaceholderOptions"
            >
              <Draggable
                v-for="task in board.columns[1].tasks"
                :key="task.idTask"
                class="draggable-item"
              >
                <!-- :color="task.color" -->
                <v-timeline-item
                  :icon="task.resourceId == null ? 'mdi-exclamation-thick' : ''"
                  :color="task.color"
                  fill-dot
                  small
                  :style="
                    task.resourceId == '00000000-0000-0000-0000-000000000000'
                      ? { border: '2px dotted red' }
                      : { border: 'none' }
                  "
                >
                  <v-row class="pt-1">
                    <v-col cols="2">
                      <span>{{ task.tactTime }} min </span>
                    </v-col>
                    <v-col cols="8" @click="goToTask(task)" class="clickable">
                      <strong>{{ task.operationName }}</strong>
                      <br />
                      ({{ task.cellName }})
                      <span v-if="task.description">
                        <br />
                        {{ task.description }}
                      </span>
                    </v-col>
                    <v-col cols="1">
                      <v-btn icon color="red" @click="getDeleteDialog(task)">
                        <v-icon dark> mdi-delete </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col> </v-col>
                  </v-row>
                </v-timeline-item>
              </Draggable>
            </Container>
          </v-timeline>
        </v-card-text>
      </v-card>
      <!-- DialogTask -->
      <DialogTask
        :edittingTask="edittingTask"
        :editInDialog="editInDialog"
        v-on:closeDialog="edittingTask = false"
      />
      <!-- DialogChangeName -->
      <DialogChangeName
        :edittingName="edittingName"
        :name.sync="name"
        v-on:closeEdittingName="edittingName = false"
        v-on:name="saveName"
      />
      <!-- DialogDelete -->
      <DialogDelete
        :deleteDialog.sync="deleteDialog"
        :toDeleteTask="toDeleteTask"
        v-on:deleteTask="deleteTask"
      />
    </v-col>
  </v-row>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { applyDrag } from "@/utils/helpers";
import { mapState } from "vuex";
import DialogTask from "@/components/dialogs/DialogTask";
import DialogChangeName from "@/components/dialogs/DialogChangeName";
import DialogDelete from "@/components/dialogs/DialogDelete";
import services from "@/services/services.js";
//import settings from "../../cfg/settings.js";

export default {
  props: {
    savingJob: {
      type: Boolean,
      required: true,
    },
    gettingJson: {
      type: Boolean,
      required: true,
    },
    showJson: {
      type: Boolean,
      required: true,
    },
    showForecastSwitch: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Container,
    Draggable,
    DialogTask,
    DialogChangeName,
    DialogDelete,
  },
  created() {
    services
      .getRoutingStepTemplates()
      .then((response) => {
        this.$store.commit("SET_ROUTINGSTEPTEMPLATES", {
          response,
        });
      })
      .catch((error) => {
        this.$store.commit("SAVE_ERROR", { error });
        console.log("There are errors: ", error.response);
      })
      .finally(() => {
        this.loading = false;
      });
  },
  data() {
    return {
      appSettings: window.__env.showSendToPropos,
      deleteDialog: false,
      toDeleteTask: {
        operationName: "",
        cellName: "",
        tactTime: "",
        description: "",
        idTask: 0,
      },
      edittingName: false,
      name: "",
      // savingJob: false,
      edittingTask: false,
      editInDialog: {
        description: "",
        name: "",
        color: "",
        image: null,
        resources: [],
        resourceId: {},
        tactTime: 15,
        id: 0,
        userAssigned: null,
      },
      dropPlaceholderOptions: {
        className: "drop-preview",
        animationDuration: "150",
        showOnTop: true,
      },
      forecast: false,
      // saveSnackbar: false,
      // snackbarSuccess: true,
      // snackbarText: "",
    };
  },
  computed: {
    showJsonButton() {
      return this.showJson && this.appSettings.showSendToPropos;
    },
    ...mapState(["board"]),
  },
  methods: {
    // drag and drop methods
    onDrop(dropResult) {
      this.board.columns[1].tasks = applyDrag(
        this.board.columns[1].tasks,
        dropResult
      );
      if (dropResult.removedIndex == null) {
        this.goToTask(this.board.columns[1].tasks[dropResult.addedIndex]);
      }
    },
    getChildPayload1(index) {
      return this.board.columns[0].tasks[index];
    },
    getChildPayload2(index) {
      return this.board.columns[1].tasks[index];
    },

    // open and save edittingTask to edit routingName
    editName(column) {
      this.name = column.name;
      this.edittingName = true;
    },
    saveName() {
      this.board.columns[1].name = this.name;
      this.edittingName = false;
    },

    // open and save edittingTask Delete
    getDeleteDialog(task) {
      this.deleteDialog = true;
      this.toDeleteTask = task;
    },
    deleteTask(task) {
      this.deleteDialog = false;
      this.$store.commit("DELETE_TASK", {
        task,
      });
    },

    // open edittingTask Task
    goToTask(task) {
      this.editInDialog = task;
      this.edittingTask = true;
    },

    // emitting methods
    saveBoard(column) {
      this.$emit("saveBoard", column);
    },
    getJson(column) {
      this.$emit("getJson", column);
    },
  },
};
</script>

<style lang="scss" scoped></style>
