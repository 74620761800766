<template>
  <v-dialog v-model="edittingName" max-width="500px">
    <v-card>
      <v-card-title> Verander Naam </v-card-title>
      <v-card-text>
        <v-text-field
          dense
          label="Naam"
          number
          v-model="nameInput"
          @keydown.enter="saveName"
        >
        </v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn color="primary" text @click="closeDialog"> Sluit </v-btn>
        <v-spacer />
        <v-btn color="primary" text @click="saveName"> Opslaan </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    edittingName: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    nameInput: {
      get: function () {
        return this.name;
      },
      set: function (newValue) {
        this.$emit("update:name", newValue);
      },
    },
  },
  methods: {
    saveName() {
      this.$emit("name", this.name);
      this.closeDialog();
    },
    closeDialog() {
      this.$emit("closeEdittingName");
    },
  },
};
</script>

<style scoped></style>
