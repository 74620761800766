export const applyDrag = (arr, dragResult) => {
  const { removedIndex, addedIndex, payload } = dragResult;
  if (removedIndex === null && addedIndex === null) return arr;

  const result = [...arr];
  let itemToAdd = JSON.parse(JSON.stringify(payload));

  if (removedIndex !== null) {
    itemToAdd = result.splice(removedIndex, 1)[0];
  } else {
    itemToAdd.routingStepTemplateId = itemToAdd.id;
    itemToAdd.id = "00000000-0000-0000-0000-000000000000";
  }

  if (addedIndex !== null) {
    result.splice(addedIndex, 0, itemToAdd);
  }

  return result;
};
