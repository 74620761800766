<template>
  <v-dialog v-model="edittingTask" max-width="500px" persistent>
    <v-card>
      <v-form v-model="valid" ref="form">
        <!-- <v-img
              height="250"
              v-if="editInDialog.image"
              :src="editInDialog.image"
            >
            </v-img> -->
        <v-card-title> {{ editInDialog.cellName }} </v-card-title>
        <v-card-text>
          <v-text-field
            dense
            label="Operation Naam"
            :rules="[rules.required]"
            v-model="editInDialog.operationName"
          ></v-text-field>
          <v-text-field
            dense
            label="Process Naam"
            :rules="[rules.required]"
            v-model="editInDialog.processName"
          ></v-text-field>
          <v-text-field
            dense
            label="Omschrijving"
            v-model="editInDialog.description"
          ></v-text-field>
          <v-autocomplete
            auto-select-first
            dense
            label="Resources"
            :rules="[rules.notEmptyGuid]"
            :items="editInDialog.resources"
            item-text="name"
            item-value="id"
            v-model="editInDialog.resourceId"
          >
          </v-autocomplete>
          <v-text-field
            dense
            label="Tact Tijd (min)"
            :rules="[rules.numbers]"
            v-model="editInDialog.tactTime"
          ></v-text-field>
          <v-text-field
            @change="busyEditting = true"
            label="Droogtijd (min)"
            :rules="[rules.numbers]"
            v-model="editInDialog.coolingTime"
            hint="24u = 1440 min, 48u = 2880 min, 72u = 4320 min"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" text @click="saveDialog"> Save </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    edittingTask: {
      type: Boolean,
      required: true,
    },
    editInDialog: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      valid: true,
      rules: {
        required: (value) => !!value || "Verplicht.",
        notEmptyGuid: (value) =>
          value != "00000000-0000-0000-0000-000000000000" || "Verplicht.",
        numbers: (value) => {
          const pattern = /^([0-9]*)$/;
          return pattern.test(value) || "Enkel nummers.";
        },
      },
    };
  },
  methods: {
    saveDialog() {
      if (this.valid) {
        this.$emit("closeDialog");
      } else {
        this.$refs.form.validate();
      }
    },
  },
};
</script>

<style scoped></style>
